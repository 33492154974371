@import url(https://fonts.googleapis.com/css?family=Lato:300,400,900);
/* Loading less */
.page-loader {
  height: 100vh;
  width: 100%;
}
.page-loader .loader-text {
  margin-left: -15px;
  margin-bottom: 10px;
  font-size: 16px;
}
.page-loader .loader-icon {
  font-size: 30px;
  font-weight: bolder;
}

/* withPendingApproval less */

/* AuthHandler less */

.help-modal .ant-modal-title {
  font-size: 18px;
}
.help-modal .content {
  font-size: 16px;
  text-align: center;
  padding: 10px 30px;
  margin: 20px 0;
}
.help-modal .help-btn-row {
  text-align: center;
  margin: 25px 0;
}
.help-modal .help-btn-row .ant-btn {
  min-width: 150px;
  font-size: 15px;
  height: 40px;
}
.help-modal .help-modal-footer .report-bug-link {
  cursor: pointer;
  font-size: 15px;
  -webkit-transition: color 0.35s;
  transition: color 0.35s;
}
.help-modal .help-modal-footer .report-bug-link:hover {
  color: #305366;
}
.help-modal .help-modal-footer .ant-btn {
  padding: 0 20px;
  font-size: 16px;
  height: 35px;
}
.help-modal .help-modal-footer .anticon {
  margin-right: 5px;
  font-size: 15px;
}

/* modalContainer less */

/* HomeHeader less */

/* HomeLayout less */

/* PrintPasswords less */
.print-passwords {
  width: 900px !important;
}
.print-passwords .ant-list-item {
  border-bottom: 1px dashed #ddd;
  font-size: 20px;
  padding: 50px 0;
}
.print-passwords .print-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
@media print {
  .print-modal {
    overflow: visible;
    position: absolute;
  }
  .print-modal .ant-modal-close-x {
    display: none;
  }
  .print-passwords {
    top: 0;
    border-radius: 0;
    width: 100% !important;
    padding-bottom: 0;
  }
  .print-passwords .ant-modal {
    padding-bottom: 0;
  }
  .print-passwords .ant-modal-body {
    padding: 0;
  }
  .print-passwords .ant-modal-content {
    border-radius: 0;
  }
}

/* ClassLink less */

/* ClassLinkModal less */

/* Field less */

/* addLoading less */

/* formModal less */

/* CreateStudentModal less */

/* TimeoutModal less */
.timeout-modal h3 {
  margin-top: 14px;
  text-align: center;
}
.timeout-modal .ant-btn {
  text-transform: uppercase;
  line-height: 44px;
  border-radius: 99px;
  height: 44px;
  margin: 30px 0;
  width: 170px;
}

/* ReauthModal less */

/* StepModal less */
.step-modal {
  display: none;
}

/* CreateClassModal less */

/* JoinClassModal less */

/* ClassModal less */

/* ColumnSelect less */
.csv-modal small {
  color: #aaa;
}
.csv-modal .hide {
  display: none;
}
.csv-modal .name-format {
  margin: 10px;
}
.csv-modal .description {
  margin-bottom: 20px;
}
.csv-modal .ant-radio-group {
  border: 1px solid #eee;
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
}
.csv-modal .ant-radio-wrapper {
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.csv-modal .ant-radio-wrapper:last-child {
  padding-bottom: 10px;
  border-bottom: none;
}

/* Mixins */
.flex-align-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.no-pointer {
  pointer-events: none;
}
.rot90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.scroller {
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
}
.absoluteCenter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
/* DisplaySheet less */
.upload-sheet .ant-row.ant-form-item {
  margin-bottom: 0;
}
.upload-sheet .ant-table-tbody > tr > td {
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upload-sheet .ant-table-tbody > tr > td input {
  padding: 22px;
  border-radius: 0;
}
.upload-sheet .ant-table-tbody > tr > td input:not(:focus) {
  border-color: transparent;
}
.upload-sheet .ant-table-tbody > tr > td .ant-form-explain {
  position: absolute;
  background: #ec5863;
  color: white;
  z-index: 999;
  top: 0px;
  right: 0;
  -webkit-transition: none;
  transition: none;
  font-size: 10px;
  line-height: 15px;
  padding: 0 4px;
  border-bottom-left-radius: 4px;
}
.upload-sheet .ant-table-tbody > tr > td .row-actions {
  padding: 0 22px;
}
.upload-sheet .email-col {
  border-right: 0 !important;
}
.upload-sheet .ant-table-body {
  max-height: 500px;
  overflow: auto;
}
.upload-sheet .ant-table-tbody > tr:hover > td {
  background: none;
}

/* StudentCsvUpload less */

/* LoginModal less */
.login-modal h3 {
  margin-top: 14px;
  text-align: center;
}
.login-modal .ant-btn {
  text-transform: uppercase;
  line-height: 44px;
  border-radius: 99px;
  height: 44px;
  margin: 30px 0;
  width: 170px;
}

/* SignInModal less */

/* AddStudentModal less */

/* StudentModal less */

.cookies-modal .ant-modal-title {
  font-size: 18px;
}
.cookies-modal .ant-modal-body {
  font-size: 16px;
}
.cookies-modal .content {
  margin: 20px 0;
  padding: 10px 25px;
  text-align: center;
}

/* Modals less */
@media print {
  body.modal-open {
    background-color: transparent !important;
  }
  body.modal-open * {
    visibility: hidden;
  }
  body.modal-open .ant-modal-wrap,
  body.modal-open .ant-modal-wrap * {
    visibility: visible !important;
  }
}

/* waitFor less */

/* Onboarding less */
.onboaring-modal .ant-form-explain {
  font-size: 13px;
  line-height: 21px;
}
.onboarding-guide-modal {
  text-align: center;
}
.onboarding-guide-modal .ant-modal-body {
  padding: 30px;
}
.onboarding-guide-modal .ant-modal-title {
  font-size: 18px;
}
.onboarding-guide-modal .content {
  font-size: 16px;
  padding: 0 45px;
}
.onboarding-guide-modal .ant-btn {
  min-width: 300px;
  font-size: 15px;
  height: 40px;
  margin: 8px 0;
}

/* UserMenu less */
.user-menu .ant-dropdown-trigger {
  cursor: pointer;
}
.user-menu .ant-avatar {
  vertical-align: middle;
  margin-right: 10px;
}

/* Sidebar less */
/* Sidebar less */
.main-sider {
  background: #305366;
  color: white;
  height: 100vh;
  overflow: hidden;
}
.main-sider .ant-layout-sider-children {
  width: 240px;
  flex-direction: column;
  background: #305366;
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  display: flex;
}
.main-sider .ant-layout-sider-children::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid transparent;
}
.main-sider .ant-layout-sider-children::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #305366;
}
.main-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: 0 0;
}
.main-sider .ant-divider {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0;
}
.main-sider .ant-menu-inline .anticon {
  font-weight: bold;
}
.main-sider .ant-menu-inline .ant-menu-item:not(:last-child),
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title:not(:last-child) {
  margin-bottom: 4px;
}
.main-sider .ant-menu-inline .ant-menu-item:hover,
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title:hover {
  background: rgba(0, 0, 0, 0.15);
}
.main-sider .ant-menu-inline .ant-menu-item.ant-menu-item-selected,
.main-sider .ant-menu-inline .ant-menu-submenu:not(.ant-menu-submenu-open) .ant-menu-submenu-title.ant-menu-item-selected {
  background: #f294bb;
}
.main-sider .ant-menu-inline .ant-menu-item-divider {
  background-color: rgba(238, 238, 238, 0.1);
}
.main-sider .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 24px !important;
  border-width: 1px 0 0;
  padding-right: 10px;
  margin: -1px 0 0;
}
.main-sider .ant-menu-inline .ant-menu-submenu-title a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.main-sider .ant-menu-inline .ant-menu-submenu-title .add-class-button {
  cursor: pointer;
}
.main-sider .ant-menu-inline .hide-arrow .ant-menu-submenu-arrow {
  display: none;
}
.join-class-button {
  width: 100%;
}
.join-class-button:hover,
.join-class-button:focus {
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
}
.join-class-button:active {
  border-color: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.main-sider .ant-menu-root {
  border: 1px solid rgba(238, 238, 238, 0.1);
  border-width: 1px 0;
}
.main-sider .ant-menu > .ant-menu-item-divider {
  margin-bottom: 5px;
}
.main-sider .ant-menu-item .anticon,
.main-sider .ant-menu-submenu-title .anticon {
  font-size: 15px;
}
.main-sider .ant-menu:not(.ant-menu-sub) > .ant-menu-item-selected,
.main-sider .ant-menu-submenu-open .ant-menu-submenu-title {
  font-weight: bold;
}
.main-sider .ant-menu-dark .ant-menu-sub {
  background: transparent;
}
.main-sider .ant-menu-submenu,
.main-sider .ant-menu-submenu-inline {
  -webkit-transition: none;
  transition: none;
}
.main-sider .ant-menu-item-divider + .ant-menu-submenu-open,
.main-sider .ant-menu-submenu-open:first-child {
  border-top-width: 0;
}
.main-sider .ant-menu-submenu-open + .ant-menu-item-divider {
  background-color: transparent;
  margin: 0;
}
.main-sider .ant-menu-submenu-open {
  margin-bottom: 5px;
  border-style: solid;
  border-width: 1px 0;
  border-color: rgba(238, 238, 238, 0.1) !important;
}
.main-sider .ant-menu-submenu-open .ant-menu-item {
  height: 35px;
  line-height: 35px;
}
.main-sider .ant-menu-submenu-open:not(.single-class) {
  background: #2b4b5c;
}
.main-sider .submenu {
  padding-bottom: 0px;
  margin-top: 4px;
  margin-bottom: 4px;
}

/* Terms less */

/* Router less */

/* Mixins */
.flex-align-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.no-pointer {
  pointer-events: none;
}
.rot90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.scroller {
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
}
.absoluteCenter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-avatar .anticon {
  margin-right: 0;
}
.ant-avatar-lg.xlg {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 60px;
}
.ant-avatar-lg.xlg > * {
  line-height: 60px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #3f6c85;
  border-color: rgba(0, 0, 0, 0.1);
}
.ant-btn.rounded {
  border-radius: 99px;
}
.ant-btn-primary.secondary {
  background-color: #f294bb;
  border-color: #f294bb;
  color: white;
}
.ant-btn-primary.secondary:hover,
.ant-btn-primary.secondary:focus {
  background-color: #f5abc9;
  border-color: #f5abc9;
}
.ant-btn-primary.secondary:active {
  background-color: #ee74a7;
  border-color: #ee74a7;
}
.ant-btn-primary.secondary.ant-btn-background-ghost {
  color: #f294bb;
}
.ant-btn-primary.secondary.ant-btn-background-ghost:hover,
.ant-btn-primary.secondary.ant-btn-background-ghost:focus {
  color: #f5abc9;
}
.ant-btn-primary.secondary.ant-btn-background-ghost:active {
  color: #ee74a7;
}
.ant-btn-primary.secondary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.secondary:after {
  border-color: #f294bb;
}
.ant-btn-primary.green {
  background-color: #54cb76;
  border-color: #54cb76;
  color: white;
}
.ant-btn-primary.green:hover,
.ant-btn-primary.green:focus {
  background-color: #68d186;
  border-color: #68d186;
}
.ant-btn-primary.green:active {
  background-color: #3bc161;
  border-color: #3bc161;
}
.ant-btn-primary.green.ant-btn-background-ghost {
  color: #54cb76;
}
.ant-btn-primary.green.ant-btn-background-ghost:hover,
.ant-btn-primary.green.ant-btn-background-ghost:focus {
  color: #68d186;
}
.ant-btn-primary.green.ant-btn-background-ghost:active {
  color: #3bc161;
}
.ant-btn-primary.green[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.green:after {
  border-color: #54cb76;
}
.ant-btn-primary.google {
  background-color: #db4437;
  border-color: #db4437;
  color: white;
}
.ant-btn-primary.google:hover,
.ant-btn-primary.google:focus {
  background-color: #df584d;
  border-color: #df584d;
}
.ant-btn-primary.google:active {
  background-color: #ca3124;
  border-color: #ca3124;
}
.ant-btn-primary.google.ant-btn-background-ghost {
  color: #db4437;
}
.ant-btn-primary.google.ant-btn-background-ghost:hover,
.ant-btn-primary.google.ant-btn-background-ghost:focus {
  color: #df584d;
}
.ant-btn-primary.google.ant-btn-background-ghost:active {
  color: #ca3124;
}
.ant-btn-primary.google[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.google:after {
  border-color: #db4437;
}
.ant-btn-primary.clever {
  background-color: #436cf2;
  border-color: #436cf2;
  color: white;
}
.ant-btn-primary.clever:hover,
.ant-btn-primary.clever:focus {
  background-color: #5b7ff4;
  border-color: #5b7ff4;
}
.ant-btn-primary.clever:active {
  background-color: #2252f0;
  border-color: #2252f0;
}
.ant-btn-primary.clever.ant-btn-background-ghost {
  color: #436cf2;
}
.ant-btn-primary.clever.ant-btn-background-ghost:hover,
.ant-btn-primary.clever.ant-btn-background-ghost:focus {
  color: #5b7ff4;
}
.ant-btn-primary.clever.ant-btn-background-ghost:active {
  color: #2252f0;
}
.ant-btn-primary.clever[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.clever:after {
  border-color: #436cf2;
}
.ant-btn-primary.classLink {
  background-color: #2a98bb;
  border-color: #2a98bb;
  color: white;
}
.ant-btn-primary.classLink:hover,
.ant-btn-primary.classLink:focus {
  background-color: #2fa9d0;
  border-color: #2fa9d0;
}
.ant-btn-primary.classLink:active {
  background-color: #23809e;
  border-color: #23809e;
}
.ant-btn-primary.classLink.ant-btn-background-ghost {
  color: #2a98bb;
}
.ant-btn-primary.classLink.ant-btn-background-ghost:hover,
.ant-btn-primary.classLink.ant-btn-background-ghost:focus {
  color: #2fa9d0;
}
.ant-btn-primary.classLink.ant-btn-background-ghost:active {
  color: #23809e;
}
.ant-btn-primary.classLink[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.classLink:after {
  border-color: #2a98bb;
}
.ant-btn-primary.yellow {
  background-color: #f6b324;
  border-color: #f6b324;
  color: white;
}
.ant-btn-primary.yellow:hover,
.ant-btn-primary.yellow:focus {
  background-color: #f7bb3c;
  border-color: #f7bb3c;
}
.ant-btn-primary.yellow:active {
  background-color: #eda40a;
  border-color: #eda40a;
}
.ant-btn-primary.yellow.ant-btn-background-ghost {
  color: #f6b324;
}
.ant-btn-primary.yellow.ant-btn-background-ghost:hover,
.ant-btn-primary.yellow.ant-btn-background-ghost:focus {
  color: #f7bb3c;
}
.ant-btn-primary.yellow.ant-btn-background-ghost:active {
  color: #eda40a;
}
.ant-btn-primary.yellow[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.yellow:after {
  border-color: #f6b324;
}
.ant-btn-primary.blue {
  background-color: #52aad5;
  border-color: #52aad5;
  color: white;
}
.ant-btn-primary.blue:hover,
.ant-btn-primary.blue:focus {
  background-color: #67b4da;
  border-color: #67b4da;
}
.ant-btn-primary.blue:active {
  background-color: #359cce;
  border-color: #359cce;
}
.ant-btn-primary.blue.ant-btn-background-ghost {
  color: #52aad5;
}
.ant-btn-primary.blue.ant-btn-background-ghost:hover,
.ant-btn-primary.blue.ant-btn-background-ghost:focus {
  color: #67b4da;
}
.ant-btn-primary.blue.ant-btn-background-ghost:active {
  color: #359cce;
}
.ant-btn-primary.blue[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-btn-clicked.blue:after {
  border-color: #52aad5;
}
/* Card less */
.ant-checkbox-wrapper.large .ant-checkbox-inner {
  height: 20px;
  width: 20px;
}
.ant-checkbox-wrapper.large .ant-checkbox-inner:after {
  height: 11px;
  left: 5px;
  top: 7px;
}
.form-row {
  margin-bottom: 0;
}
.ant-layout-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}
.ant-layout-header.dark {
  background: #305366;
}
.ant-table .ant-table-thead > tr > th {
  background: transparent;
  font-weight: bold;
}
.ant-table .ant-table-tbody > tr:hover > td,
.ant-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent !important;
}
.ant-table-thead tr.ant-table-row-hover > td,
.ant-table-tbody tr.ant-table-row-hover > td,
.ant-table-thead .ant-table-tbody tr:hover > td,
.ant-table-tbody .ant-table-tbody tr:hover > td {
  background-color: rgba(82, 170, 213, 0.15);
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: transparent;
}
.ant-table-tbody > tr > td.ant-table-column-sort tr,
.ant-table-thead tr,
.ant-table-tbody > tr > td.ant-table-column-sort > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: transparent;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  height: 8px;
}
.ant-table-column-sorter-up.on i,
.ant-table-column-sorter-down.on i,
.ant-table-column-sorter-up.on:hover i,
.ant-table-column-sorter-down.on:hover i {
  color: #305366;
}
.ant-table-column-sorter-up i,
.ant-table-column-sorter-down i,
.ant-table-column-sorter-up:hover i,
.ant-table-column-sorter-down:hover i {
  color: #ccc;
  font-size: 17px !important;
}
.ant-table-column-sorter-up:hover i,
.ant-table-column-sorter-down:hover i {
  opacity: 0.85;
}
.ant-menu-horizontal.center-items {
  text-align: center;
}
.ant-menu-horizontal.center-items .ant-menu-item {
  float: none;
  display: inline-block;
}
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 33px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 7px 12px;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled).danger,
.ant-dropdown-menu-submenu-title:not(.ant-dropdown-menu-item-disabled).danger {
  color: #ec5863;
}
.ant-menu-vertical-right .ant-menu-submenu-title {
  padding-left: 33px;
}
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span .anticon:first-child,
.ant-dropdown-menu-submenu-title > span .anticon:first-child,
.ant-dropdown-menu-item > a .anticon:first-child,
.ant-dropdown-menu-submenu-title > a .anticon:first-child {
  margin-right: 8px;
  min-width: 12px;
}
.ant-menu .ant-btn .anticon {
  margin: 0;
}
.ant-dropdown-menu-light {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-submenu-title {
  font-weight: bolder;
}
.ant-modal-title {
  text-align: center;
  font-size: 1.17em;
}
.full-page-modal {
  width: 100%;
  padding: 0;
}
.full-page-modal .ant-modal {
  padding-bottom: 0;
  margin: 0 !important;
}
.full-page-modal .ant-modal-close > span {
  font-size: 24px;
}
.full-page-modal .full-page-back {
  position: absolute;
  margin: 15px;
  font-size: 32px;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
  z-index: 1;
}
.full-page-modal .full-page-back:hover {
  opacity: 0.9;
}
.full-page-modal .full-page-back:active {
  opacity: 1;
}
.full-page-modal .ant-modal-body {
  overflow: auto;
  height: 100vh;
}
.full-page-modal .ant-modal-content {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.93);
  height: 100vh;
}
@media print {
  .ant-modal-content {
    box-shadow: none;
  }
}
.ant-tooltip {
  pointer-events: none;
}
.ant-tooltip.small {
  font-size: 12px;
}
.activity-dot-tooltip .ant-tooltip-inner {
  text-align: center;
  max-width: 185px;
}
.ant-tag-checkable {
  cursor: pointer;
  background: #fafafa;
  border: 1px solid #d9d9d9;
}
.ant-tag-checkable.ant-tag-checkable-checked {
  background: #305366;
}
@media print {
  .no-print {
    display: none !important;
  }
  .print-no-break {
    page-break-inside: avoid;
  }
}
body {
  font-family: Lato, Helvetica Neue, Helvetica, Arial;
}
.flex-grow {
  flex: 1 1 auto;
  width: 0;
}
.btn-flash {
  -webkit-animation: btn-flash 700ms 10;
          animation: btn-flash 700ms 10;
}
@-webkit-keyframes btn-flash {
  from {
    box-shadow: 0 0 0 0px rgba(242, 148, 187, 0.9);
  }
  to {
    box-shadow: 0 0 0 10px rgba(242, 148, 187, 0);
  }
}
@keyframes btn-flash {
  from {
    box-shadow: 0 0 0 0px rgba(242, 148, 187, 0.9);
  }
  to {
    box-shadow: 0 0 0 10px rgba(242, 148, 187, 0);
  }
}
.blue {
  color: #52aad5;
}
.lighter {
  font-weight: lighter;
}
.disabled {
  opacity: 0.75;
}
.link {
  color: #52aad5;
  cursor: pointer;
}
.link:hover {
  opacity: 0.8;
}
.disabled-link {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  color: #333333;
}
.ant-card {
  overflow: hidden;
}
.ant-card:not(.ant-card-bordered) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.ant-card .ant-card-head-title {
  line-height: 30px;
}
.ant-card .ant-card-head-title .ant-avatar {
  margin-right: 10px;
}
.ant-card .ant-card-body {
  padding-top: 15px;
}
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4 {
  letter-spacing: 1px;
  font-weight: 100;
}
h3 {
  font-weight: normal;
}
h4 {
  font-weight: 600;
}
.padding-0 {
  padding: 0;
}
.col-lg {
  max-width: 1100px;
}
.main-col {
  padding: 0px 50px;
  margin: 0 auto;
  max-width: 1100px;
}
.hide {
  display: none !important;
}
.hidden {
  visibility: hidden;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
.completed-progress {
  width: 90px;
  white-space: nowrap;
  margin-right: 15px;
  margin-bottom: -5px;
  text-align: center;
}
.completed-progress .ant-progress-inner,
.completed-progress .ant-progress-bg {
  border-radius: 0;
}
.completed-progress .ant-progress-inner {
  background: #ddd;
}
/** FORMS **/
.text-counter {
  position: absolute;
  bottom: -29px;
  right: 8px;
  z-index: 1;
  opacity: 0.5;
  font-size: 12px;
  pointer-events: none;
}

